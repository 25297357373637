/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Image from "next/image";

function ImageGallery() {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <div className="overflow-hidden relative mb-8">
      <Swiper
        slidesPerView={4}
        spaceBetween={30}
        slidesPerGroup={1}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        autoplay={{
          delay: 4500,
          disableOnInteraction: true,
        }}
        modules={[Navigation, Autoplay]}
        onSwiper={(swiper) => {
          swiper.navigation.update();
        }}
        breakpoints={{
          100: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          500: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          700: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          900: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1400: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <div>
            <div className="flex flex-col flex-shrink justify-center items-center">
              <div className="pb-8 min-w-[100px] p-4 m-16 bg-gray-100 rounded-xl w-[200px] h-[200px] sm:w-[220px] sm:h-[220px] md:w-[280px] md:h-[280px] lg:w-[320px] lg:h-[320px]">
                <div className="text-xs sm:text-xs md:text-base">
                  <h1 className="text-xs sm:text-xs md:text-base">
                    "I’ve never seen such a wonderful representation of the
                    colors and textures in a giclée print! Really unique and
                    special experience for my family."
                  </h1>
                  <p className="text-lg pt-4">Pierre A.</p>
                  <span className="text-yellow-400">{"★".repeat(5)}</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="flex flex-col flex-shrink justify-center items-center">
              <div className="pb-8 min-w-[100px] p-4 m-16 bg-gray-100 rounded-xl w-[200px] h-[200px] sm:w-[220px] sm:h-[220px] md:w-[280px] md:h-[280px] lg:w-[320px] lg:h-[320px]">
                <div className="text-xs sm:text-xs md:text-base">
                  <h1 className="text-xs sm:text-xs md:text-base">
                    "My children, 4 and 6, had the incredible experience of
                    creating their own piece one night, and now it will live
                    above our fireplace forever."
                  </h1>
                  <p className="text-lg pt-4">Dylan M.</p>
                  <span className="text-yellow-400">{"★".repeat(5)}</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="flex flex-col flex-shrink justify-center items-center">
              <div className="pb-8 min-w-[100px] p-4 m-16 bg-gray-100 rounded-xl w-[200px] h-[200px] sm:w-[220px] sm:h-[220px] md:w-[280px] md:h-[280px] lg:w-[320px] lg:h-[320px]">
                <div className="text-xs sm:text-xs md:text-base">
                  <h1 className="text-xs sm:text-xs md:text-base">
                    "I have been playing with GenAI for over a year now, but
                    Raraity is the first platform where I have been able to
                    create something that I love."
                  </h1>
                  <p className="text-lg pt-4">Geri P.</p>
                  <span className="text-yellow-400">{"★".repeat(5)}</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="flex flex-col flex-shrink justify-center items-center">
              <div className="pb-8 min-w-[100px] p-4 m-16 bg-gray-100 rounded-xl w-[200px] h-[200px] sm:w-[220px] sm:h-[220px] md:w-[280px] md:h-[280px] lg:w-[320px] lg:h-[320px]">
                <div className="text-xs sm:text-xs md:text-base">
                  <h1 className="text-xs sm:text-xs md:text-base">
                    "Love it! I ordered a small piece on a whim, and I love it
                    so much I'm designing a huge piece for my living room.
                    Recommend upgrading the glass option as it is crystal
                    clear."
                  </h1>
                  <p className="text-lg pt-4">Jessi L.</p>
                  <span className="text-yellow-400">{"★".repeat(5)}</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="flex flex-col flex-shrink justify-center items-center">
              <div className="pb-8 min-w-[100px] p-4 m-16 bg-gray-100 rounded-xl w-[200px] h-[200px] sm:w-[220px] sm:h-[220px] md:w-[280px] md:h-[280px] lg:w-[320px] lg:h-[320px]">
                <div className="text-xs sm:text-xs md:text-base">
                  <h1 className="text-xs sm:text-xs md:text-base">
                    "Was looking for a really specific color match for this
                    bright green color that I couldn't find anywhere in art. A
                    few minutes on Raraity got me exactly what I was looking
                    for."
                  </h1>
                  <p className="text-lg pt-4">Parker B.</p>
                  <span className="text-yellow-400">{"★".repeat(5)}</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      {/* Custom navigation buttons */}
      <button
        ref={navigationPrevRef}
        className="custom-swiper-button-next absolute top-1/2 left-0 ml-2 transform -translate-y-1/2 p-1 z-10 border border-black rounded-full"
      >
        <ArrowBackIosNewIcon className="flex justify-center items-center text-base" />
      </button>
      <button
        ref={navigationNextRef}
        className="custom-swiper-button-next absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 p-1 z-10 border border-black rounded-full"
      >
        <ArrowForwardIosIcon className="flex justify-center items-center text-base" />
      </button>
    </div>
  );
}

export default ImageGallery;
