/* eslint-disable @next/next/no-img-element */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect } from "react";
import { NextPage } from "next";
import Link from "next/link";
import Image from "next/image";
import dynamic from "next/dynamic";
import GallerySlider from "components/GallerySlider";
import CustomerReviewsCarousel from "components/CustomerReviewsCarousel";
import Testimonial from "../components/Testimonial";
import { HOME_IMAGES } from "../data";

const ContentLayoutFooterNoChartreuse = dynamic(
  () => import("../components/Layout/ContentLayoutFooterNoChartreuse"),
);

const HomePage: NextPage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % HOME_IMAGES.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <ContentLayoutFooterNoChartreuse title="Home">
      <div className="relative overflow-hidden h-[90vh]">
        <Image
          alt="HomePage"
          src={HOME_IMAGES[currentImageIndex]}
          fill
          sizes="100vw"
          className="absolute top-0 left-0 w-full h-full object-cover transition-all duration-1000 ease-in-out"
        />
        {/* <img
          className="absolute top-0 left-0 w-full h-full object-cover transition-all duration-1000 ease-in-out"
          src="/home/hamptons_centered_4_2.webp"
          alt=""
        /> */}
        {/* <Image
          alt="Raraity"
          src="/Raraity_Logo_White.svg"
          width={800}
          height={200}
          className="absolute top-[58%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full"
        /> */}
        {/* Top wavy SVG */}
        <svg
          className="absolute inset-x-0 top-[-45px] h-1/6 w-screen"
          viewBox="100 0 1000 100"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* First wave */}
          <path
            fill="#FFFFFF"
            d="M0 33C240 90 480 10 720 33C960 57 1200 -20 1440 33V0H0V33Z"
          />

          {/* Second wave */}
          <path
            fill="#FFFFFF"
            d="M0 50C240 90 480 60 720 50C960 40 1200 90 1440 50V15H0V50Z"
          />
        </svg>
        {/* Bottom wavy SVG */}
        <svg
          className="absolute inset-x-0 bottom-[-40px] h-1/6 w-screen"
          viewBox="-100 0 1000 100"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* First wave */}
          <path
            fill="#FFFFFF"
            transform="translate(1000, 100) scale(-1, -1)"
            d="M0 33C240 90 480 10 720 33C960 57 1200 -20 1440 33V0H0V33Z"
          />

          {/* Second wave */}
          <path
            fill="#FFFFFF"
            transform="translate(1000, 100) scale(-1, -1)"
            d="M0 50C240 90 480 60 720 50C960 40 1200 90 1440 50V15H0V50Z"
          />
        </svg>
        {/* <h3 className="text-4xl font-bold absolute inset-0 flex flex-col items-center justify-center top-[35%] text-white">
          Wall Art from $100
        </h3> */}
        <div className="absolute inset-0 flex flex-col items-center justify-center top-[18%]">
          <Link href="/create-story">
            <button
              className="bg-black text-white font-bold py-3 px-10 text-3xl rounded-full z-9999"
              type="button"
            >
              Create Art
            </button>
          </Link>
        </div>
      </div>
      <h1 className="text-black text-4xl md:text-6xl text-center tracking-wide pt-10 pb-6 md:pt-16 md:pb-4">
        Create Your Masterpiece With AI
      </h1>
      <div className="flex justify-center items-center md:flex-row flex-col mb-16 md:m-4 m-8">
        {/* Icon and Description 1 */}
        <div className="mt-4 md:mt-12 mb-2 items-start bg-gray-100 px-8 py-6 md:p-8 rounded-lg m-4 w-[280px] h-[280px] sm:w-[300px] sm:h-[300px] md:w-[230px] md:h-[230px] lg:w-[280px] lg:h-[280px]">
          <div className="flex flex-col justify-center items-center mt-4">
            <span>Step 1</span>
            <span className="text-3xl md:text-2xl font-bold text-black">
              Create
            </span>
            <p className="flex justify-center items-center text-center text-l md:text-l lg:text-xl text-black mt-4">
              Customize art that does not exist anywhere in the world until you
              create it.
            </p>
          </div>
        </div>
        {/* Icon and Description 2 */}
        <div className="mt-4 md:mt-12 mb-2 items-start bg-gray-100 px-8 py-6 md:p-8 rounded-lg m-4 w-[280px] h-[280px] sm:w-[300px] sm:h-[300px] md:w-[230px] md:h-[230px] lg:w-[280px] lg:h-[280px]">
          <div className="flex flex-col justify-center items-center mt-4">
            <span>Step 2</span>
            <span className="text-3xl md:text-2xl font-bold text-black">
              Frame
            </span>
            <p className="text-l md:text-l lg:text-xl text-black mt-4 text-center">
              Print, frame and mount to meet museum standards.
            </p>
          </div>
        </div>
        {/* Icon and Description 3 */}
        <div className="mt-4 md:mt-12 mb-2 items-start bg-gray-100 px-8 py-6 md:p-8 rounded-lg m-4 w-[280px] h-[280px] sm:w-[300px] sm:h-[300px] md:w-[230px] md:h-[230px] lg:w-[280px] lg:h-[280px]">
          <div className="flex flex-col justify-center items-center mt-4">
            <span>Step 3</span>
            <span className="text-3xl md:text-2xl font-bold text-black">
              Collect
            </span>
            <p className="text-l md:text-l lg:text-xl text-black mt-4 text-center">
              Assembled and shipped to your door in 2-weeks.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center pt-12 pb-24 md:pt-12 md:pb-24">
        <Link href="/create-story">
          <button
            className="bg-black text-white font-bold py-3 px-10 text-3xl rounded-full"
            type="button"
          >
            Create Art
          </button>
        </Link>
      </div>
      {/* <div className="testimonial-section">
        <Testimonial />
      </div> */}
      <GallerySlider />
      <div className="flex flex-col md:flex-row md:justify-evenly justify-center items-center mt-8">
        <div className="flex flex-col justify-center items-center py-8">
          <h1 className="flex md:w-1/2 text-center justify-center items-center mb-8 px-4">
            Create Your Story With AI
          </h1>
          <Link href="/create-story">
            <button
              className="bg-black text-white font-bold py-3 px-8 text-xl rounded-full z-9999"
              type="button"
            >
              Try For Free
            </button>
          </Link>
        </div>
        <img
          className="md:w-[48%] w-[80%] max-w-[400px] rounded-lg border border-grey mt-12"
          src="/home/framedartstudio2.png"
          alt=""
        />
      </div>
      <CustomerReviewsCarousel />
      {/* <div className="flex flex-col-reverse md:flex-row md:justify-evenly justify-center items-center mt-0 mb-16 pb-16">
        <img
          className="md:w-[48%] w-[80%] max-w-[400px] rounded-lg"
          src="/home/framedartstudio2.png"
          alt=""
        />
        <div className="flex flex-col justify-center items-center py-16">
          <h1 className="flex md:w-1/2 text-center justify-center items-center mb-8">
            Live With Museum Quality
          </h1>
          <Link href="/details">
            <button
              className="bg-black text-white font-bold py-3 px-8 text-xl rounded-full z-9999"
              type="button"
            >
              Learn More
            </button>
          </Link>
        </div>
      </div> */}
    </ContentLayoutFooterNoChartreuse>
  );
};

export default HomePage;
